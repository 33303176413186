<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-12 col-xxl-12 honor-container mt-80-mb-140">
        <div class="content" v-for="item in honour" :key="item">
          <div class="container img-container">
            <div class="img-box row">
              <div
                v-for="img in item.image"
                :key="img"
                class="img-li col-xxl-3 col-xl-3 col-6"
              >
                <img :src="img" alt="" />
              </div>
            </div>
          </div>
          <ul class="title-ul">
            <li
              v-for="title in item.title"
              :key="title"
              class="position-relative"
            >
              {{ title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { getHonour } from '../../../server/intoHuadu'
const honour = ref([])

onMounted(async () => {
  honour.value = await getHonour()
})
</script>
<style lang="stylus" scoped>
.honor-container
  .content
    margin-bottom 50px
    &:last-child
      margin-bottom 0
    .title-ul
      padding-left 0 !important
      padding-top 35px
      li
        margin-bottom 15px
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height 30px
        padding-left 20px
        &::before
          content '.'
          position absolute
          left 0
          top 0
          font-size 40px
          line-height 0
        @media screen and (max-width 576px) {
          font-size: 14px;
          margin-bottom 5px
          line-height 20px
        }
    .img-container
      padding 0 !important
      .img-box
        margin-top -50px
        @media screen and (max-width 576px) {
          margin-top -10px
        }
        .img-li
          margin-top 50px
          height 180px
          @media screen and (max-width 576px) {
            height 160px
            margin-top 10px
          }
          img
            width 100%
            height 100%
            object-fit cover
</style>
